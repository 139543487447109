<template>
  <div class="assess">
    <div class="title">
      <div>衍生品认知评估</div>
      <div style="margin-top: 10px;">
        衍生投资产品的认知评估(包括但不限于ETF/牛熊证/窝轮/期货/期权等)
      </div>
    </div>
    <van-cell-group>
      <template #title>
        <div class="question">您是否有意进行衍生产品的买卖</div>
      </template>
      <van-radio-group
        v-model="form.accept_derive"
        :disabled="add_account_type === '证券保证金账户-期货账户' || accept_derive_disabled"
      >
        <van-radio name="是">是</van-radio>
        <van-radio name="否">否</van-radio>
      </van-radio-group>
    </van-cell-group>
    <div v-if="form.accept_derive === '是'">
      <van-cell-group>
        <template #title>
          <div class="question">1.您是否曾接受有关衍生产品性质和风险的一般知识或修读相关课程?</div>
        </template>
        <van-radio-group v-model="form.learned_derive">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell-group>
      <van-cell-group>
        <template #title>
          <div class="question">2.您现时或过去是否从事与衍生产品有关的工作?</div>
        </template>
        <van-radio-group v-model="form.work_with_derive">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell-group>
      <van-cell-group>
        <template #title>
          <div class="question">
            3.您是否在过去三年曾买卖过至少五次任何衍生产品的交易 (不论是否在交易所买卖)?
          </div>
        </template>
        <van-radio-group v-model="form.buyed_derive">
          <van-radio name="是">是</van-radio>
          <van-radio name="否">否</van-radio>
        </van-radio-group>
      </van-cell-group>
      <div class="read" v-if="form.accept_derive === '是'">
        [{{ form.derive_sense === "是" ? "已" : "未" }}阅读]
        <span class="link" @click="link('assess_sense')">衍生产品基本常识</span>
      </div>
      <div class="read" v-if="form.accept_derive === '是'">
        [{{ form.derive_report === "是" ? "已" : "未" }}阅读]
        <span class="link" @click="link('assess_report')">“认识一般衍生产品”简报</span>
      </div>
    </div>
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="check" :disabled="disabledNext">
        下一步
      </van-button>
      <van-button class="btn" @click="before">
        上一步
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, CellGroup, Dialog, Cell, Field, RadioGroup, Radio } from "vant";
import { reactive, watch, ref, computed } from "vue";
import { useStore } from "vuex";
import httpApi from "../../utils/httpApi";
import httpAddApi from "../../utils/httpAddApi";

let submitForm;
const message = `根据您的选择，您在开户成功后，账户将不能交易衍生产品。您可重新检查选项或联系客服。
客服电话：xxx`;
export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  data() {
    return {
      add_account_type: "",
      accept_derive_disabled: false,
      major_invester: "",
      addInfo: null
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    onSheetSelect(value) {
      this.isShowSheet = false;
      this.form.value2 = value.name;
    },
    check() {
      const { accept_derive, learned_derive, work_with_derive, buyed_derive } = this.form;
      const allFalse =
        learned_derive === "否" && work_with_derive === "否" && buyed_derive === "否";
      // 开户状态下不能选择三个否则
      if (accept_derive === "是" && allFalse) {
        this.$toast("开通衍生产品买卖，三项知识经验需具备至少一项！");
        return;
      }
      if (accept_derive === "否" || allFalse) {
        if (this.disabled && !this.isAddAccount) {
          this.next("/majorOption/step_one");
          return;
        }
        Dialog.confirm({
          title: "说明",
          message,
          confirmButtonText: "重新检查",
          cancelButtonText: "不检查了",
          messageAlign: "left",
          confirmButtonColor: "rgba(0,0,0,0.6)"
        }).catch(async () => {
          if (this.major_invester === "是" && this.isAddAccount) {
            if (this.addInfo.add_account_type !== this.addInfo.account_type) {
              this.next("/riskDisclosure/step_one");
            } else {
              this.next("/riskDisclosure/step_two");
            }
          } else {
            this.next("/majorOption/step_one");
          }
        });
      } else {
        this.next("/assess/step_one");
      }
    },
    async link(name) {
      const httprequest = this.isAddAccount
        ? httpAddApi.updateDerivativeAssessmentChoices
        : httpApi.postAsessIndex;
      const result = await httprequest(submitForm);
      if (!result) return;
      this.$router.push({ name });
    },
    async next(path) {
      if (this.form.derive_sense !== "是" || this.form.derive_report !== "是") {
        if (this.form.accept_derive === "是") {
          this.$toast("您尚未阅读全部衍生产品知识！");
          return;
        }
      }
      if (this.disabledNext.value) return;
      const httprequest = this.isAddAccount
        ? httpAddApi.updateDerivativeAssessmentChoices
        : httpApi.postAsessIndex;
      const { code, msg } = await httprequest(submitForm);
      if (code === 200) {
        if (this.isAddAccount) await this.$api.submitLog({ type: "submitAddDerivativeAssessment" });
        this.$router.push({ path });
      } else {
        this.$toast(msg);
      }
    },
    before() {
      this.$router.go(-1);
    },
    async refresh() {
      this.$store.commit("setTitleValue", "衍生产品");
      if (this.isAddAccount) {
        this.$store.commit("setStepsValue", ["风险认知(1/4)", "提交资料"]);
        this.$store.commit("setStepsActive", 0);
      } else {
        this.$store.commit("setStepsValue", ["填写资料", "风险认知(1/4)", "提交资料"]);
        this.$store.commit("setStepsActive", 1);
      }
      const httprequest = this.isAddAccount
        ? httpAddApi.getDerivativeAssessmentChoices
        : httpApi.getAsessIndex;
      const result = await httprequest();
      if (!result) return;
      this.form.accept_derive = "";
      this.form.learned_derive = "";
      this.form.work_with_derive = "";
      this.form.buyed_derive = "";
      Object.getOwnPropertyNames(this.form).forEach(item => {
        if (result.data[item]) {
          this.form[item] = result.data[item];
        }
      });
      if (this.isAddAccount) {
        const addAccountInfo = await httpAddApi.getOpenAccountInfo();
        if (addAccountInfo) {
          this.add_account_type = addAccountInfo.add_account_type || addAccountInfo.account_type;
          this.major_invester = addAccountInfo.major_invester;
          this.addInfo = addAccountInfo;
          if (this.add_account_type === "证券保证金账户-期货账户") this.form.accept_derive = "是";
        }
        const resu = await httpApi.getAsessIndex();
        if (resu && resu.data.accept_derive === "是") {
          this.form.accept_derive = "是";
          this.accept_derive_disabled = true;
        }
      } else {
        const res = await httpApi.getChooseStepOne();
        this.add_account_type = res.data.account_type;
        if (this.add_account_type === "证券保证金账户-期货账户") this.form.accept_derive = "是";
      }
    }
  },
  setup() {
    const form = reactive({
      accept_derive: "",
      learned_derive: "",
      work_with_derive: "",
      buyed_derive: "",
      derive_sense: "",
      derive_report: ""
    });
    const disabledNext = ref(true);
    watch(form, val => {
      submitForm = { ...val };

      if (submitForm.accept_derive === "否") {
        delete submitForm.learned_derive;
        delete submitForm.work_with_derive;
        delete submitForm.buyed_derive;
      }
      const { accept_derive, derive_sense, derive_report } = submitForm;
      disabledNext.value = false;
      Object.getOwnPropertyNames(submitForm).forEach(item => {
        if (item !== "derive_sense" && item !== "derive_report") {
          if (!submitForm[item].trim()) {
            disabledNext.value = true;
          }
        }
      });
      if (accept_derive === "是" && (derive_sense !== "是" || derive_report !== "是")) {
        disabledNext.value = true;
      }
      submitForm.derive_sense = val.derive_sense;
      submitForm.derive_report = val.derive_report;
      httpApi.formLog(submitForm, disabledNext);
    });
    const store = useStore();
    const isAddAccount = computed(() => store.state.user.isAddAccount);
    return {
      form,
      // checkFalse, // 2021.8.1 改为使用 accept_derive
      disabledNext,
      isAddAccount
    };
  }
};
</script>

<style lang="less">
.assess {
  padding-top: @space-2;
  .title {
    text-align: left;
    margin-left: 16px;
    color: @font-color-1;
    font-size: @font-size-3;
  }
  .question {
    text-align: left;
    color: @font-color-0;
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
    margin-bottom: 0;
  }
  .van-radio-group {
    padding-top: @space-3;
    display: flex;
    padding-left: @space-0;
    .van-radio {
      flex-basis: 50%;
      padding-bottom: @space-3;
    }
  }
  .read {
    padding-left: @space-1;
    margin-top: @space-3;
    text-align: left;
  }
}
</style>
